exports.components = {
  "component---node-modules-sudaraka-94-gatsby-theme-novela-src-pages-404-js": () => import("./../../../node_modules/@sudaraka94/gatsby-theme-novela/src/pages/404.js" /* webpackChunkName: "component---node-modules-sudaraka-94-gatsby-theme-novela-src-pages-404-js" */),
  "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2021-12-06-go-embed-instead-of-packr-index-md": () => import("./../../../node_modules/@sudaraka94/gatsby-theme-novela/src/templates/article.template.tsx?__contentFilePath=/home/runner/work/sudaraka94.github.io/sudaraka94.github.io/content/posts/2021-12-06-go-embed-instead-of-packr/index.md" /* webpackChunkName: "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2021-12-06-go-embed-instead-of-packr-index-md" */),
  "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2021-12-10-installing-openjdk-on-m-1-mac-index-md": () => import("./../../../node_modules/@sudaraka94/gatsby-theme-novela/src/templates/article.template.tsx?__contentFilePath=/home/runner/work/sudaraka94.github.io/sudaraka94.github.io/content/posts/2021-12-10-installing-openjdk-on-m1-mac/index.md" /* webpackChunkName: "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2021-12-10-installing-openjdk-on-m-1-mac-index-md" */),
  "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2024-04-10-gatsby-js-for-dummys-index-mdx": () => import("./../../../node_modules/@sudaraka94/gatsby-theme-novela/src/templates/article.template.tsx?__contentFilePath=/home/runner/work/sudaraka94.github.io/sudaraka94.github.io/content/posts/2024-04-10-gatsby-js-for-dummys/index.mdx" /* webpackChunkName: "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2024-04-10-gatsby-js-for-dummys-index-mdx" */),
  "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2024-04-29-handling-ei-expose-rep-index-mdx": () => import("./../../../node_modules/@sudaraka94/gatsby-theme-novela/src/templates/article.template.tsx?__contentFilePath=/home/runner/work/sudaraka94.github.io/sudaraka94.github.io/content/posts/2024-04-29-handling-ei-expose-rep/index.mdx" /* webpackChunkName: "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2024-04-29-handling-ei-expose-rep-index-mdx" */),
  "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2024-06-06-neural-network-from-scratch-index-mdx": () => import("./../../../node_modules/@sudaraka94/gatsby-theme-novela/src/templates/article.template.tsx?__contentFilePath=/home/runner/work/sudaraka94.github.io/sudaraka94.github.io/content/posts/2024-06-06-neural-network-from-scratch/index.mdx" /* webpackChunkName: "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2024-06-06-neural-network-from-scratch-index-mdx" */),
  "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2024-10-13-high-kafka-lag-during-deployments-index-mdx": () => import("./../../../node_modules/@sudaraka94/gatsby-theme-novela/src/templates/article.template.tsx?__contentFilePath=/home/runner/work/sudaraka94.github.io/sudaraka94.github.io/content/posts/2024-10-13-high-kafka-lag-during-deployments/index.mdx" /* webpackChunkName: "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2024-10-13-high-kafka-lag-during-deployments-index-mdx" */),
  "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2024-12-22-high-cardinality-tags-in-metrics-index-mdx": () => import("./../../../node_modules/@sudaraka94/gatsby-theme-novela/src/templates/article.template.tsx?__contentFilePath=/home/runner/work/sudaraka94.github.io/sudaraka94.github.io/content/posts/2024-12-22-high-cardinality-tags-in-metrics/index.mdx" /* webpackChunkName: "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-article-template-tsx-content-file-path-content-posts-2024-12-22-high-cardinality-tags-in-metrics-index-mdx" */),
  "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-articles-template-tsx": () => import("./../../../node_modules/@sudaraka94/gatsby-theme-novela/src/templates/articles.template.tsx" /* webpackChunkName: "component---node-modules-sudaraka-94-gatsby-theme-novela-src-templates-articles-template-tsx" */)
}

